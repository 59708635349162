import React, { useState } from "react";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const buttonClass = "text-green-100 font-bold text-sm px-2 py-1 hover:bg-green-100 hover:text-green-800 rounded-lg transition duration-300"
    const buttons = (
        <>
            <a className={buttonClass} href="/service">Our Services</a>
            <a className={buttonClass} href="/about">About Us</a>
            <a className={buttonClass} href="/client">Client Portal</a>
            <a className={buttonClass} href="/employee">Employee</a>
            <a className={buttonClass} href="/contact">Contact Us</a>
        </>
    )
    return (
        <nav className={`${isOpen ? "bg-green-800 text-white fixed top-0 w-full z-[1000] isolate" : "bg-transparent text-white fixed top-0 w-full z-[1000] isolate"}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex flex-row w-full justify-between">
                        <div className="text-xl font-bold">
                            <a href="/">
                                Energeneco
                            </a>
                        </div>
                        <div className="hidden md:block">
                            <div className="flex ml-10 items-baseline space-x-2">
                                {buttons}
                            </div>
                        </div>
                    </div>
                    <div className="md:hidden">
                        <button onClick={()=>(setIsOpen(!isOpen))} type='button' className="fill-green-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 6l16 0" />
                                <path d="M4 12l16 0" />
                                <path d="M4 18l16 0" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {
                isOpen && (
                    <div className="bg-green-800 flex flex-col gap-y-4 md:hidden px-4 sm:px-6 pb-2">
                        {buttons}
                    </div>
                )
            }
        </nav>
    )
}

export default Header