import React from 'react';
//import './bar.css'
import '../App.css'

const Bar = ({ title, sub, l1i, lid, l2i, l2d, l3i, l3d }) => {
    return (
        <div className='h-[800px] w-[350px] bg-white rounded-xl'>
            <img src={`${process.env.PUBLIC_URL}/water.jpg`} alt="service" />
            <div className='my-3'>
                <p class='kanit-bold' >{title}</p>
            </div>
            <div div className='p-2'>
                <p class='ubuntu-regular' className='mb-10'>
                    {sub}
                    <div div className='px-4 text-left'>
                        <ul style={{ listStyleType: 'disc' }} className='mt-10'>
                            <li>
                                <p className='font-bold'>
                                    {l1i}
                                </p>
                                {lid}</li>
                            <li>
                                <p className='font-bold'>
                                    {l2i}
                                </p>
                                {l2d}</li>
                            <li>
                                <p className='font-bold'>
                                    {l3i}
                                </p>
                                {l3d}
                            </li>
                        </ul>
                    </div>
                </p>
            </div>
        </div>
    );
};

export default Bar;