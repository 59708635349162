import '../../App.css'
import Header from '../../components/header';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function Contact() {

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [alert, setAlert] = useState(0);

  const printInfo = () => {
    if (fname.trim().length === 0 | lname.trim().length === 0 | email.trim().length === 0 | msg.trim().length === 0) { 
      setAlert(2);
      return
    }
    console.log('TextField value:', fname);
    console.log('TextField value:', lname);
    console.log('TextField value:', email);
    console.log('TextField value:', msg);
    setFname('');
    setLname('');
    setEmail('');
    setMsg('');
    setAlert(1);
  };

  const handleFnameChange = (event) => {
    setFname(event.target.value);
  };

  const handleLnameChange = (event) => {
    setLname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMsgChange = (event) => {
    setMsg(event.target.value);
  };

  const buttonTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              color: 'white',
              fontWeight: 'bold',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', 
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#002411',
              },
              '&.Mui-focused .MuiInputBase-input': {
                color: 'white',
                fontWeight: 'bold',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
              fontWeight: 'bold',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#002411',
              fontWeight: 'bold',
            },
          },
        },
      },
    },
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(0);
  };
  
  const getAlertMessage = (alert) => {
    switch (alert) {
      case 1:
        return (
          <Alert severity="success">
            You have successfully sent a message!
          </Alert>
        );
      case 2:
        return (
          <Alert severity="error">
            Please fill in all text fields.
          </Alert>
        );
      default:
        return null; 
    }
  };

  

  return (
    <div className="App mt-16">
      <Header/>
      <Snackbar
        open={alert != 0}
        autoHideDuration={2000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {getAlertMessage(alert)}
      </Snackbar>
      <div className="h-[300px]"></div>
      <div className="h-[800px] lg:h-[450px] bg-green-800 flex flex-col lg:flex-row justify-center items-center">
          <div className='lg:w-3/12 flex'>
          <div className="flex flex-col items-center min-w-4/12">
            <div className='lg:text-4xl text-6xl text-white p-4'>
            <p class='kanit-bold'>Contact Us</p>
            </div>
            <div div className='p-4 lg:max-w-lg w-[80%]'>
            <p class='ubuntu-regular lg:text-xl text-2xl text-white'>
            Feel free to reach out to us if you have any questions or concerns
            </p>
            </div>
          </div>
          </div>
          <div className="flex flex-col items-center min-w-4/12">
          <ThemeProvider theme={buttonTheme}>
            <div>
            <TextField id="filled-basic" label="Your First Name" variant="filled" sx={{
                width: { xs: '235px'},
              }} 
              value={fname}
              onChange={handleFnameChange}
            />
            <TextField id="filled-basic" label="Your Last Name" variant="filled" sx={{
                width: { xs: '235px'},
              }}
              value={lname}
              onChange={handleLnameChange}
            />
            </div>
            <TextField id="filled-basic" label="Your Email" variant="filled" sx={{
                width: { xs: '235px', md: '470px' },
              }}
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              id="filled-multiline-static"
              label="Your Message"
              multiline
              rows={4}
              variant="filled"
              sx={{
                width: { xs: '235px', md: '470px' },
              }}
              value={msg}
              onChange={handleMsgChange}
            />
          </ThemeProvider>
          <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
          onClick={printInfo}>
              Submit
            </button>
          </div>
      </div>
      <div className="h-[300px]"></div>
    </div>
  );
}

export default Contact;
