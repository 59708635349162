import '../../App.css'
import Header from '../../components/header';
import React, { useRef, useEffect, useState } from 'react';

function About() {

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="App mt-16">
      <Header/>
      <div className="h-[150px] bg-green-800"></div>
      <div className='bg-green-800 text-white'>
      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className="flex flex-col items-center md:w-[700px] w-[80%]">
          <div className='text-3xl p-4'>
            <p class='kanit-bold'>Our Mission</p>
            
          </div>
          <div div className='p-4'>
          <p class='ubuntu-regular'>
          At Energeneco LLC, we are dedicated to driving the global transition towards a more sustainable 
          future. As a forward-thinking consultancy and technology provider, we combine our expertise in 
          renewable energy solutions with cutting-edge analytics to empower business across all sectors to 
          embrace the benefits of wind and solar power. Our team is passionate about helping companies 
          meet their energy needs while reducing their carbon footprint, enhancing operational efficiency, 
          and contributing to a greener planet. 
          </p>
          </div>
        </div>
      </div>
      </div>
      <div className="h-[150px] bg-green-800"></div>
      <div className="h-[150px]"></div>

      <div className='z-[10] relative'>

      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className="flex flex-col items-center md:w-[700px] w-[80%]">
          <div className='text-3xl p-4'>
          <p class='kanit-bold'>Our Story
          </p>
          </div>
          <div div className='p-4'>
          <p class='ubuntu-regular'>
            Founded in 2024, Energeneco LLC began with a vision to bridge the gap between traditional
            industries and renewable energy. With an initial focus on wind energy, we have since expanded our
            expertise to solar energy and developed our own innovative data analysis platform that streamlines
            energy forecasting, performance monitoring, and statistical insights for businesses of all sizes. Our
            consultancy works closely with clients, guiding them through the complexities of renewable energy
            adoption with personalized strategies that yield measurable results.
          </p>
          </div>
        </div>
      </div>
      </div>
      <div className="h-[150px]"></div>
      <div className="h-[100px] bg-gray-200"></div>
      <div className="mx-10px bg-gray-200">
        <div className='flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text'>
          <div className="flex flex-col items-center md:w-[700px] w-[80%]">
            <div className='text-3xl p-4'>
            <p class='kanit-bold'>Why Choose Us?</p>
            </div>
            <div div className='p-4'>
            <p class='ubuntu-regular'>
            <ul style={{ listStyleType: 'disc' }}>
              <li>Tailored Solutions: We offer customized renewable energy strategies that align with the
                  unique needs of your business.</li>
              <li>Data-Driven Approach: Our advanced analytics app provides in-depth insights to help
                  optimize energy usage and increase efficiency.</li>
              <li>Expert Team: Our team consists of expertise in wind and solar energy, engineering, and
                  sustainability</li>
              <li>Commitment to Sustainability: We believe in making a positive environmental impact and
                  helping industries transition to cleaner energy sources.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[100px] bg-gray-200"></div>
      <div className="h-[100px]"></div>
      <div className="mx-10px">
        <div className='flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text'>
          <div className="flex flex-col items-center md:w-[700px] w-[80%]">
            <div className='text-3xl p-4'>
            <p class='kanit-bold'>Our Vision</p>
            </div>
            <div div className='p-4'>
            <p class='ubuntu-regular'>
            To become a leading global partner for businesses transitioning to renewable energy by offering
            innovative, data-driven solutions that contribute to a sustainable future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[150px]"></div>
    </div>
  );
}

export default About;
