import '../../App.css'
import Header from '../../components/header';

function Employee() {
  return (
    <div className="App mt-16">
      <Header/>
      This is employee page
    </div>
  );
}

export default Employee;
