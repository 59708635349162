import '../../App.css'
import Header from '../../components/header';

function Client() {
  return (
    <div className="App mt-16">
      <Header/>
      This is client page
    </div>
  );
}

export default Client;
