import '../../App.css'
import Header from '../../components/header';
import React, { useRef, useEffect, useState } from 'react';
import Bar from '../../components/bar';

function Service() {

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  const [startIndex, setStartIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleLeftClick = () => {
    if (startIndex > 0) {
      setDirection('left');
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (startIndex < 2) {
      setDirection('right');
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const testData = [1,2,3,4,5]

  const getBar = (index) => {
    switch(index) {
      case 1:
        return <>
          <Bar 
            title='1. Renewable Energy Consulting' 
            sub='We provide expert consulting services to help your business integrate wind and solar energy
          solutions seamlessly into your operations. From energy audits to feasibility studies, our team
          guides you through every step of the renewable energy transition.' 
            l1i='Energy Efficiency Audits:' 
            lid='Assess your current energy usage and identify opportunities
              to reduce waste and lower costs through renewable energy solutions.'
            l2i='Feasibility Studies:'
            l2d='Analyze the potential of wind and solar installations of your
              business based on geographical and operational factors.'
            l3i='Custom Strategy Development:'
            l3d='Develop tailored energy plans that maximize
              renewable energy usage and align with your business goals.'/>
        </>
      case 2:
        return <>
        <Bar 
          title='2. Wind & Solar Data Analytics' 
          sub='Our cutting-edge app delivers powerful data analysis tools to optimize wind and solar energy
            usage, providing detailed performance insights, energy production forecasts, and operational
            efficiency recommendations.' 
          l1i='Wind Farm Performance Monitoring:' 
          lid='Track real-time energy output, identify trends,
                and optimize wind farm performance with advanced analytics'
          l2i='Energy Production Forecasting:'
          l2d='Use historical data to predict future energy generation
              and plan operations accordingly.'
          l3i='Custom Reporting:'
          l3d='Generate comprehensive reports on energy usage, performance,
              and potential areas of optimization.'/>
      </>
      case 3:
        return <>
        <Bar 
          title='3. Energy Transition Roadmap' 
          sub='Whether you’re in the non-renewable sector or seeking to expand your sustainability efforts, we
            provide a step-by-step roadmap to integrate renewable energy into your operations while
            ensuring regulatory compliance and maximizing returns on investment.' 
          l1i='Renewable Integration Plans:' 
          lid='Develop a roadmap for incorporating wind or solar
              energy into your existing operations.'
          l2i='Regulatory Compliance Support:'
          l2d='Ensure your business meets local and international
              energy regulations and sustainability standards.'
          l3i='Carbon Footprint Reduction Strategies:'
          l3d='Create actionable plans to reduce your
              carbon emissions using renewable energy.'/>
      </>
      case 4:
        return <>
        <Bar 
          title='4. Customized Software Solutions' 
          sub='Our in-house development team offers custom software solutions, including API integrations
            and data management platforms, to help you streamline your energy operations and manage
            data with ease.' 
          l1i='App Development:' 
          lid='Get access to custom features and interfaces built to meet your
              specific energy management needs.'
          l2i='API Integration:'
          l2d='Seamlessly integrate our data analysis platform with your existing
              systems to enhance real-time decision-making.'
          l3i='Mobile Solutions:'
          l3d='Monitor your energy data on the go with mobile-friendly solutions.'/>
      </>
      case 5:
        return <>
        <Bar 
          title='5. Renewable Energy Partnerships & Collaborations' 
          sub='We work alongside manufacturers, research institutions, and government bodies to deliver
            comprehensive renewable energy solutions that drive industry-wide change' 
          l1i='Turbine and Solar Panel Manufacturers:' 
          lid='Collaborate with us to integrate data-driven
              solutions into your energy hardware for enhanced performance.'
          l2i='Research Projects:'
          l2d='Partner with academic institutions to advance renewable energy
              research and innovation using our data analysis tools.'
          l3i='Government and Public Sector Projects:'
          l3d='Provide policy insights and renewable energy
              strategies to municipalities and government agencies.'/>
      </>
    }
  }

  return (
    <div className="App mt-16">
      <Header/>
      <div className="h-[150px] bg-green-800"></div>
      <div className='bg-green-800 text-white'>
      <div className='border-2 border-gray-300 w-4/5 rounded-lg overflow-hidden mx-auto max-w-[700px]'>
          <video width="100%" controls>
            <source src={`/test_vid.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </div>
      </div>
      <div className="h-[100px] bg-green-800"></div>
      <div className="h-[1000px] bg-gray-200 flex justify-center items-center">
        <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text space-x-10">
        <button 
        class="w-24 h-24 bg-white text-gray text-5xl rounded-full flex items-center justify-center hover:bg-gray-300 focus:outline-none disabled:bg-gray-300"
        onClick={handleLeftClick}
        disabled={startIndex === 0}
        >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        </button>
          {testData.slice(startIndex, startIndex + 3).map((index) => (
            <div
             key={index}
             className={`transition-transform duration-300 ${direction === 'left' ? 'slide-left' : 'slide-right'}`}
            >
            {getBar(index)}
            </div>
        ))}
        <button 
        class="w-24 h-24 bg-white text-gray text-5xl rounded-full flex items-center justify-center hover:bg-gray-300 focus:outline-none disabled:bg-gray-300"
        onClick={handleRightClick}
        disabled={startIndex === 2}
        >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
        </button>
        </div>
      </div>
      <div className="h-[100px] bg-gray-200"></div>
    </div>
  );
}

export default Service;
