import logo from './logo.svg';
import './App.css';
import './styles/global.css';
import Header from './components/header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Client from './pages/client';
import Contact from './pages/contact';
import Employee from './pages/employee';
import Service from './pages/service';
import React, { useRef, useEffect, useState } from 'react';
import FullScreenVideo from './components/FullScreenVid';
import AutoPlaySilentVideo from './components/video';

function Home() {

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="App mt-16">
      <FullScreenVideo/>
      <Header/>
      <div className="h-[150px] bg-green-800"></div>
      <div className='bg-green-800 text-white'>
      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className="flex flex-col items-center min-w-4/12">
          <div className='text-3xl p-4'>
            <p class='kanit-bold'>About Us</p>
            
          </div>
          <div div className='p-4 md:max-w-lg w-[80%]'>
          <p class='ubuntu-regular'>
          Connecting traditional industry with renewable energy, we offer cutting-edge solutions to help business grow while saving the environment. Starting with the wind energy, we have already expanded our expertise on the solar energy to work closely with clients in order to navigating them through the renewable energy.
          </p>
          </div>
          <a href="/about">
            <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
            Learn More
            </button>
          </a>
        </div>
      </div>
      </div>
      <div className="h-[150px] bg-green-800"></div>
      <div className="h-[150px]"></div>

      <div className='z-[10] relative'>

      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className='md:w-3/12 flex justify-center'>

        <div className='w-full max-w-[500px] min-h-[200px] mx-auto sm:w-[90%] sm:ml-[5%] sm:mr-[5%]'>
        <img src={`${process.env.PUBLIC_URL}/water.jpg`} alt="service" />
        </div>
        </div>
        <div className="flex flex-col items-center min-w-4/12">
          <div className='text-3xl p-4'>
          <p class='kanit-bold'>Our Services</p>
          </div>
          <div div className='p-4 md:max-w-lg w-[80%]'>
          <p class='ubuntu-regular'>
          Our services consists of renewable energy consulting, wind & solar data analytics, energy transition roadmap, customized software solutions, and renewable energy partnerships & collaborations
            </p>
          </div>
          <a href="/service">
           <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
            Learn More
           </button>
          </a>
        </div>
      </div>
      </div>
      <div className="h-[150px]"></div>
      <div className="h-[150px] bg-gray-200"></div>
      <div className="mx-10px bg-gray-200">
        <div className='flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text'>
          <div className='w-3/12 flex justify-center'>
            <div className='w-full max-w-[500px] min-h-[200px] mx-auto sm:w-[90%] sm:ml-[5%] sm:mr-[5%]'>
            <img src={`${process.env.PUBLIC_URL}/chicken.png`} alt="contact" />
            </div>
          </div>
          <div className="flex flex-col items-center min-w-4/12">
            <div className='text-3xl p-4'>
            <p class='kanit-bold'>Contact Us</p>
            </div>
            <div div className='p-4 md:max-w-lg w-[80%]'>
              <p class='ubuntu-regular'>
              Have questions or feedback? We’d love to hear from you! Feel free to reach out, and our team will get back to you as soon as possible.
              </p>
            </div>
            <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
              <a href="/contact">
              Contact Us
              </a>
            </button>
          </div>
        </div>

        
      </div>

      <div className="h-[250px] bg-gray-200"></div>
    </div>
  );
}

function Home_alt() {

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
      <div>
        <Header/>
            <div className="relative h-[500px] md:h-[900px] w-full overflow-hidden">
            <AutoPlaySilentVideo 
                className="absolute top-0 left-0 w-full h-full object-cover" 
                video={`${process.env.PUBLIC_URL}/landing_video.mp4`} 
            />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <img className="h-[75px] w-[125px]" src={`${process.env.PUBLIC_URL}/EnergenecoLOGO.png`} alt="service" />
        <p className="text-white text-3xl md:text-5xl text-center md:my-10">
            WELCOME TO
          </p>
          <p className="text-green-800 text-5xl md:text-8xl font-bold text-center my-7">
            ENERGENECO
          </p>
          <p className="text-white text-lg md:text-3xl font-bold text-center md:mb-[100px] mb-[50px]">
            POWERING TOMMOROW, LIFTING TODAY
          </p>
          <a href="/about">
          <button className='focus:outline-none text-white bg-transparent hover:bg-white hover:text-green-700 border border-white focus:ring-4 focus:ring-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'>
            Learn More
            </button>
          </a>
        </div>
        </div>
        <AutoPlaySilentVideo 
                className="absolute top-[500px] md:top-[900px] left-0 w-full sm:h-[900px] h-[600px] object-cover" 
                video={`${process.env.PUBLIC_URL}/landing_video3.mp4`} 
            />
        <div className="absolute top-[500px] md:top-[900px] left-0 w-full sm:h-[900px] h-[600px] bg-white opacity-90"/>
        <div className="sm:h-[900px] h-[600px] flex justify-center items-center">
          <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
          <div className='md:w-3/12 flex justify-center'>
          <div className='w-full max-w-[1000px] h-[200px] mx-auto sm:w-[90%] sm:ml-[5%] sm:mr-[5%] my-7'>
          <video width="100%" 
                loop
                autoPlay
                muted
                playsInline>
            <source src={`${process.env.PUBLIC_URL}/landing_video2.mp4`} type="video/mp4" />
          </video>
          </div>
          </div>
          <div className="flex flex-col items-center min-w-4/12">
            <div className='sm:text-6xl text-3xl p-4 text-green-800'>
            <p class='kanit-bold'>Wind Development</p>
            </div>
            <div div className='p-4 sm:text-2xl md:max-w-lg w-[80%]'>
            <p class='ubuntu-regular'>
            We’re driven by a mission to shape a sustainable, innovative future through specialized consulting in wind and solar energy development.
            </p>
            </div>
          </div>
        </div>
        </div>
        <div className=" top-[500px] md:top-[900px] left-0 w-full sm:h-[900px] h-[600px] bg-green-800 flex justify-center items-center">
        <p className="text-white text-5xl md:text-8xl font-bold text-center my-7">
            WIND ENGINEERING
          </p>
        </div>
      </div>
  );
}


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home_alt />} />  
          <Route path="/about" element={<About />} />  
          <Route path="/client" element={<Client />} />  
          <Route path="/contact" element={<Contact />} />  
          <Route path="/employee" element={<Employee />} />  
          <Route path="/service" element={<Service />} />  
        </Routes>
      </div>
    </Router>
  );
}

export default App;
